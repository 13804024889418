import { render, staticRenderFns } from "./GSelect.vue?vue&type=template&id=3af3669b&scoped=true&"
import script from "./GSelect.vue?vue&type=script&lang=js&"
export * from "./GSelect.vue?vue&type=script&lang=js&"
import style0 from "./GSelect.vue?vue&type=style&index=0&id=3af3669b&scss=true&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3af3669b",
  null
  
)

export default component.exports