<template>
 <div :style="getAppStyle()+'width: 100%; height: 100%; position: relative;'">
   
     <div class='transition' :style='getAppStyle()+getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div style='float: top; width: 100%; vertical-align: middle;'>
		        <div style='vertical-align: middle; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'>TRACKER 1.0 </div>
		        <div style='vertical-align: middle; text-align: center; font-size: 11pt; font-weight: bold;width: 100%;'>Operations Optimisation</div>
		        <div style='vertical-align: middle; text-align: center; font-size: 11pt; background-color: #fee; color: red; font-weight: bold;width: 100%;'>INPUT</div>
		        <!-- 
		        <div style='padding-top: 10pt; vertical-align: middle; text-align: center; font-size: 10pt; font-weight: bold;width: 100%;'>CONTROLS</div>
		         -->
		        <br/>
		        <span class='SVcontrolLabel SVFont'>{{tr("SalesUnit")}}:</span><br/>
		        <GSelectSimple :options="unitOptions" v-model="selectedUnit.id" :clearable="clearable()" @change="doReload()" placeholder=''>  
        		</GSelectSimple>
        		
        		
        		<span class='SVcontrolLabel SVFont'>Status:</span><br/>
		        <GSelectMSimple :multiple='true' :options="entityMap.status.data" v-model="statusArray" :clearable="clearable()"  placeholder=''>  
        		</GSelectMSimple>
        		
		        <br/>
		        <br/>
		    	<div>
			    <div class='SVFont' style='display: inline-flex; width: 95%;'>
			    <InputDatePick  class='SVFont' :auto="false"
                            v-model="fromDate"/>  
                            </div>

                <div class='SVFont' style='display: inline-flex; width: 95%;'>
                <InputDatePick class='SVFont' :auto="false"
                            v-model="untilDate"/>     
                 </div>           

				</div>
		        <br/>
		    	<button :style='getAppStyle()' :disabled='newLine' class='button SVFont' @click="createNewLine">new line</button>
		    	<button :style='getAppStyle()' class='button SVFont' :disabled='!rowChanged.length' @click="saveAll()">save all</button>
		    	<button :style='getAppStyle()' title='reload all data' class='button SVFont' @click="doReload"><mdicon size="18" name='reload'/></button>
		    	<br/>
		        <br/>
		        <button :style="getAppStyle()"  :disabled="!allowed.includes('/workflowOperationsTrafficAssign')" class='button xbutton SVFont' @click="storeSettings(); $router.replace('/workflowOperationsTrafficAssign')">Completion Screen
		    	<span v-if='notifyTraffic.todo' title="ToDo's" class='w3-badge w3-blue'>{{notifyTraffic.todo}}</span>
		    	<span v-if='notifyTraffic.issues' title="Issues" class='w3-badge w3-red'>{{notifyTraffic.issues}}</span>
		    	</button>
		    	<button :style="getAppStyle()" :disabled="!allowed.includes('/workflowOperationsTrafficDelivery')" class='button xbutton SVFont' @click="storeSettings(); $router.replace('/workflowOperationsTrafficDelivery')">Delivery Screen
		    	<span v-if='notifyDelivery.todo' title="ToDo's" class='w3-badge w3-blue'>{{notifyDelivery.todo}}</span>
		    	<span v-if='notifyDelivery.issues' title="Issues" class='w3-badge w3-red'>{{notifyDelivery.issues}}</span>
		    	</button>
			    
			    <br/>
			    <br/>
			    <span class='dontWrap smallText bold SVFont'>Font-Size:</span>
			    <div style='width: 75%;padding-left: 5pt;'>
				<vue-slider @change='val => fontChanged(val)' :marks=true min=8 max=16 v-model="fontSize"></vue-slider>	
				</div>
				<br/>
		    	<br/>
		    	<span class='SVcontrolLabel SVFont'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
		    	<br/>

				
		    </div> <!-- CONTROLS -->
    </div>
    
    <!-- MAIN -->
	<div class='transition' style='float: left; width: 86%; '> 
	    <!-- HEADER -->
	    <div style='float: top; width: 100%; height: 0pt; '>
		
	  	</div>
	    <!-- HEADER -->
	    
	    <!-- TABLE -->
	    <div style='width: calc(100%); height: calc(100vh - 120px);'  >
	    	
	    	
	    	<div style='float: top; width: 100%; height: 28pt; overflow-y: scroll;'>
			    <div style='float: top;'>
				    <div v-for="(col,idx) in columns" :key="'ColH_'+idx" class='floatLeft tableHeader' :style='getColumnStyle(col, idx)'
						@click='setOrderBy(col)'
						>
				       <div class='header higher' 
				            :style="'background-color: '+getBGColor('#eef','#66b')">
				       		<div v-if="col.icon"><mdicon :width="16" name="paperclip" /><sub>{{getOrderBy(col)}}</sub></div>
				       		<div v-else> {{ col.name }}<sub>{{getOrderBy(col)}}</sub></div>
				       	</div>
				    </div>
				   
			    </div>  <!-- float: top -->   
			</div> <!-- HEADER -->
	    	<div style='width: 100%; height: calc(75vH); overflow-y: scroll;' class='transition' >
			    	<div  class='transition' style='float: top; width: 100%;height: 100%;' >
			    	    
			    	    
			    	    <div v-for="(line,idx) in limit(data)" 
			    	         @contextmenu="ctxMenu($event, line, idx)"
			    	         class='reqLine transition' 
			    	         :key="'DAT_'+idx" 
			    	         :id="'DAT_'+idx" 
			    	         :ref="'DAT_'+idx"
			    	         @drop="dropFile($event, line, idx, 'DAT_'+idx);"
			    	         @dragenter.prevent="hoverLine('DAT_'+idx)"
			    	         @dragleave.prevent="leaveLine('DAT_'+idx)"
			    	        
			    	         @dragover.prevent="hoverLine('DAT_'+idx)"
			    	         @mouseenter="hoverLine('DAT_'+idx)"
			    	         @mouseleave="leaveLine('DAT_'+idx)"
			    	        >
  
			    	         
			    	        <div v-for="(col,idx2) in columns" :key="'CS_'+idx+'_'+idx2" class='floatLeft transition'  :style='getColumnStyle(col, idx2)'>
			    	           <div v-if="!col.show" class='reqDetail dontWrap' >
			    	           	<button :style="getAppStyle()" title='save' v-if="rowChanged[idx] && editable(line)" type='button' style='font-size:7pt; ' class='myButton' @click='update(line,idx)'><mdicon :width="16" name="content-save" /></button>
			    	           	<button :style="getAppStyle()" title='save' v-else type='button' disabled style='font-size:7pt; ' class='myButton'><mdicon :width="16" name="content-save" /></button>
								<button :style="getAppStyle()" title='delete' type='button' style='font-size:7pt; ' @click='deleteLine(line)' class='myButton'><mdicon :width="16" name="delete" /></button>
								<button :style="getAppStyle()" title='send to traffic' type='button' :disabled='!line.id' style='font-size:7pt; ' @click='sendToTraffic(line, idx)' class='myButton'><mdicon :width="16" name="send" /></button>
			    	           </div>
						       <div v-else class='reqDetail dontWrap' :style='getColumnDetailStyle(col, line)' @click='edit(idx, idx2, col, line)' >
								    <div v-if='!col.disabled && editCell==idx2 && editRow==idx && col.show=="productName" && products 
									    && products[line.advertiserName] && products[line.advertiserName].length' style='width: 100%;' >
										
										<GSelect2 ref="ifldEdit"
										        :options="products[line.advertiserName]" 
												:visible="true"
	 					                        :showOpener="false"
	 					                        :column="col.show"
	 					                        :time="line.id"
	 					                        :fontSize="10"
	 					                        :disabled='!editable(line)'
	 					                        :clearable="false"
	 					                        @close="closeEdit()"
	 					                        @tab="jumpToNext=true"
	 					                         v-model="line[col.show]"
	 					                        :data=line :id=line.id />
									</div>	
						            <div v-else-if='!col.disabled && editCell==idx2 && col.show=="advertiserName" && editRow==idx 
									         && col.type=="txt"' style='width: 100%;'>

										   <InputTextWS search='true' entity="Client" subType="Advertiser"
										            searchImmed='99' 
											        :fontSize='9' :disabled='!editable(line)' 
												    :clipboard='false' ref='ifldEdit' v-model="line.advertiserName" 
											        @tab="jumpToNext=true" @enter='closeEdit(col, line)' @escape='cancelEdit()'/>


						            </div>
									<div v-else-if='!col.disabled && editCell==idx2 && col.show=="agencyName" && editRow==idx 
									         && col.type=="txt"' style='width: 100%;'>

										   <InputTextWS search='true' entity="Client" subType="Agency"
										            searchImmed='99' 
											        :fontSize='9' :disabled='!editable(line)' 
												    :clipboard='false' ref='ifldEdit' v-model="line.agencyName" 
											        @tab="jumpToNext=true" @enter='closeEdit(col, line)' @escape='cancelEdit()'/>


						            </div>
									<div v-else-if='!col.disabled && editCell==idx2 && editRow==idx && col.type=="txt"' style='width: 100%;'>
															            	
										<InputText :search='true' @search="closeEdit(col, line)" :fontSize='fontSize' :disabled='!editable(line)' :clipboard='false' ref='ifldEdit' v-model="line[col.show]" @tab="jumpToNext=true" @enter='closeEdit(col, line)' @blur='closeEdit(col, line)' @escape='cancelEdit()'/>

									</div>
						            <div v-else-if='!col.disabled && editCell==idx2 && editRow==idx && col.type=="int"' >
						            	<InputInt :disabled='!editable(line,col)' :clipboard='false' ref='ifldEdit' v-model="line[col.show]" @tab="jumpToNext=true" @enter='closeEdit()' @blur='closeEdit()' @escape='cancelEdit()' />
						            </div>
						            <div v-else-if='!col.disabled && editCell==idx2 && editRow==idx && col.type=="dbl"' >
						            	<InputDouble :disabled='!editable(line)' :clipboard='false' ref='ifldEdit' v-model="line[col.show]" @tab="jumpToNext=true" @enter='closeEdit()' @blur='closeEdit()' @escape='cancelEdit()' />
						            </div>
						            <div v-else-if='!col.disabled && editCell==idx2 && editRow==idx && col.type=="date"' style='width: 100%;' >
						            	<InputDatePick :disabled='!editable(line)' ref='ifldEdit'
						            	@close="cancelEdit()"
			                            @change="closeEdit()" 
										@blur="closeEdit()" 
										@enter="closeEdit()" 
			                            @escape="cancelEdit()"
			                            @tab="jumpToNext=true" 
			                            
			                            v-model="line[col.show]"/>    
						            </div>
									
									<div v-else-if='!col.disabled && editCell==idx2 && editRow==idx && col.show=="channelId" && col.type=="select"' style='width: 100%;' >
										
										<GSelect2 ref="ifldEdit"
										        :options="stations" 
												:visible="true"
	 					                        :showOpener="false"
	 					                        :column="col.show"
	 					                        :time="line.id"
	 					                        :fontSize="10"
	 					                        :disabled='!editable(line)'
	 					                        :clearable="false"
	 					                        @close="closeEdit()"
	 					                        @tab="jumpToNext=true"
	 					                         v-model="line[col.show]"
	 					                        :data=line :id=line.id />
									</div>	
						            <div v-else-if='!col.disabled && editCell==idx2 && editRow==idx && col.type=="select"' style='width: 100%;' >
						            	<GSelect ref="ifldEdit"
					                        :visible="true"
					                        :showOpener="false"
					                        :column="col.show"
					                        :entityMap="entityMap"
					                        :time="line.id"
					                        :fontSize="fontSize"
					                        :disabled='!editable(line)'
					                        :clearable="false"
					                        @close="closeEdit()"
					                        @tab="jumpToNext=true"
					                         v-model="line[col.show]"
					                        :data=line :id=line.id :metadata=metadata :definition="findColumn(col.show)" />
 
						            </div>
						            <div v-else-if='!col.disabled && editCell==idx2 && editRow==idx && col.type=="mny"' >
						            	<InputMoney ref='ifldEdit' :clipboard='false' v-model="line[col.show]" @tab="jumpToNext=true" @enter='closeEdit()' @blur='closeEdit()' @escape='cancelEdit()'
						            	 :currency=line.currencyId :disabled='!editable(line)'
			                            :dataMap=entityMap.Currency
			                            />
						            </div>
						            <div v-else-if='col.show === "attachments"' @click='showAttachments(line)'>
						            	{{print(line, col)}}
						            </div>
						            <div v-tooltip='line.commentTraffic' v-else-if='col.show === "status"' >
						            	<div v-if='line.commentTraffic' style='color: red; font-weight: bold;'>
						            		{{print(line, col)}}
						            	</div>
						            	<div v-else>
						            		{{print(line, col)}}
						            	</div>
						            </div>
						            <div :title='print(line, col)' v-else class='transition'>
						       			{{print(line, col)}}
						       		</div>
						       	</div>
						     </div> 

				    	        
			    	    </div>
			    		
			    	</div> 
		    	</div>  <!-- TABLE -->


    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->
    
   <GFWEOpenEditor ref='editor' @update='doReload'></GFWEOpenEditor>
   
   <ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData && contextData.data"  slot-scope="{ contextData }">
        
		<ContextMenuItem  @clicked="$refs.menu.close(); sendToTraffic(contextData.data, contextData.row);">
        	send to traffic
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem  @clicked="$refs.menu.close(); $refs.editor.open('SalesRequest', contextData.data.id); ">
        	Edit {{contextData.data.name}} {{contextData.data.status}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.data.status == 1" @clicked="$refs.menu.close(); deleteLine(contextData.data);">
        	Delete {{contextData.data.name}}
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem  @clicked="$refs.menu.close(); setStatus(contextData.data, 9, contextData.row) ">
        	set to 'on Hold'
        </ContextMenuItem>
        <ContextMenuItem  @clicked="$refs.menu.close(); setStatus(contextData.data, 1, contextData.row) ">
        	set back to 'active'
        </ContextMenuItem>
        <ContextMenuItem  @clicked="$refs.menu.close(); setStatus(contextData.data, 10, contextData.row) ">
        	set to 'cancelled'
        </ContextMenuItem>


       </template>
    </ContextMenu>
    <PDFViewer name='pdfView' ref='pdfView' />
    <GConfirm ref='confirm'/>
    
 </div>
</template>

<script>
import {HTTP, HTTPMP, APIURL, opsAPI, fwAPI, invAPI, reportAPI, setReload, myLocale, formatNumber, getSalesUnit, showError} from '@/variables.js';
import { tr } from '@/translate.js';
import { defer } from '@/utils.js';
import { store, restore} from '@/OperationScreen.js';
import { getAppStyle, initAppMode, isDarkMode,getFG,getBG,getBGColor } from '@/AppStyle.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputTimezone from '@/components/inputElements/InputTimezone';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import InputText from '@/components/inputElements/InputText';
import InputMoney from '@/components/inputElements/InputMoney';
import InputDouble from '@/components/inputElements/InputDouble';
import InputInt from '@/components/inputElements/InputInt';
//import GSelectSimple from '@/components/misc/GSelect';
import GSelectSimple from '@/components/GSelectSimple';
import GSelectMSimple from '@/components/GSelectMSimple';
import GTableJSON from '@/components/GTableJSON';
import clipboard from '@/components/Clipboard';
import GConfirm from '@/components/GConfirm';
import PDFViewer from '@/components/PDFViewer';

import ClickOutside from 'vue-click-outside'
import GSelect from '@/components/GSelect';
import GSelect2 from '@/components/Ops/GSelect';
import InputTextWS from '@/components/inputElements/InputTextWithSearch';
//import FileUploadOutline from 'vue-material-design-icons/FileUploadOutline';
import JQuery from "jquery";
import 'w3-css/w3.css';
let $ = JQuery;

var timers = [];

export default {
  name: 'GFW_TASKER',
  components : {
   PDFViewer, InputTextWS,
   'app-switch': Switch, GConfirm, GSelectMSimple, InputDatePick, InputDouble, GSelect2, GSelectSimple,  ContextMenu, ContextMenuItem, ContextMenuSep, InputText, InputInt, InputMoney, GSelect
  },
  data () {
    return {
      stationId: 0,
      station: {},
      selectedStation: {},
      currency: {},
      stations: [],
      name: "",
      fromDate: {},
      untilDate: {},
      loadingActive: false,
      controlsRight: false,
      statusArray: [1,7,9],
      allowed: [],
	  products: {},
	  clients: {},
      fullUnits: [],
      unitOptions: [],
      selectedUnit: {},
      selectedUnitId: null,
      
      data: [],
      metadata: {},
      entityMap: [],
      editRow: -1,
      jumpToNext: false,
      editCell: -1,
      editField: {},
      rowChanged: [],
      valueBefore: {},
      
      dropRow: -1,
      
      tr,
      
      fileCount: 0,
	  isInitial: true,
	  isSaving: false,
	  notifyTraffic: {},
	  notifyDelivery: {},
      
      allDetailIDs: [],
      detailEntityMap: {},
      selectedDetail: {},
      metadataDetail: {},
      detailCounter: 0,
      showEditorDetail: false,
      
      selectorInput: null,
      
      newLine: null,
      getAppStyle,
      getBGColor,
      fontSize: 11,
      widthCalculated: 100,
      orderBy: [],
      columns: [
	        {name: "Reference", show: "name", type: 'txt', w: 5},
	        {name: "Vers", show: "version", type: 'int', w: 2},
			{name: "Advertiser", show: "advertiserName", entity: "Advertiser", type: 'txt', w: 8},
	        {name: "Agency", show: "agencyName", entity: "Client", type: 'txt', w: 8},
	        
	        {name: tr("Product"), show: "productName", entity: "Product", type: 'txt', w: 8},
	        {name: "Spots", show: "noOfSpots", type: 'int', w: 2},
	        {name: "GRP", show: "grp", type: 'dbl', w: 2},
	        {name: "Budget", show: "net", type: 'mny', w: 4},
	        {name: "From", show: "fromDate", type: 'date',w: 5},
	        {name: "To", show: "untilDate", type: 'date',w: 5},
			
	        {name: "Channel", show: "channelId", type: 'select', w: 5},
	        {name: "Type", show: "typeId", type: 'select', w: 4},
	       // {name: tr("SalesUnit"), show: "salesUnitId", type: 'select', w: 4},
	        {name: tr("Currency"), show: "currencyId", type: 'select', w: 4},
	        {name: "Received", show: "received", type: 'date', w: 3},
	        {name: "Manager", show: "fromUser", type: 'select', w: 4},
	        {name: "Status", show: "status", type: 'select', disabled: true,w: 4},
	        {name: "attachment", show: "attachments", type: 'select', icon:true, disabled: true, w: 1},
	        {name: "Action", show: "", w: 5}
        ]
    }
  },    
  directives:{
    ClickOutside,
  },
  methods: {
    myLocale() { return myLocale();},
    editable(line, col) 
    { 
    	let editable = ((!line) || (!line.status) || (line.status==1) || (line.status==7));
    	if ( !editable)
    	{
    		return false;
    	}
    	if ( col && col.show=='grp' && line.noOfSpots)
    	{
    		return false;
    	}
    	if ( col && col.show=='noOfSpots' && line.grp)
    	{
    		return false;
    	}
    	return true;
    },
    hoverLine(line)
    {
    	if ( isDarkMode())
    	{
    		$('#'+line).children().addClass('reqLineBGDM');
    	}
    	else
    	{
    		$('#'+line).children().addClass('reqLineBG');
    	}
    },
    leaveLine(line)
    {
    	if ( isDarkMode())
    	{
    		$('#'+line).children().removeClass('reqLineBGDM');
    	}
    	else
    	{
    		$('#'+line).children().removeClass('reqLineBG');
    	}
    },
    parseName( name )
    {
    	if ( name )
    	{
    		// reverse
    		let reverse = name.split("").reverse().join("");
    		let numberAtEnd = 0; // get number at end
    		let size = name.length;
    		let tenP = 1;
    		for ( let c = 0; c < reverse.length; c++)
    		{
    			let ch = reverse.charAt(c);
    			if (  (ch < '0' || ch > '9'))
	    		{
	    			break;
	    		}
	    		numberAtEnd = numberAtEnd + tenP*(ch-'0');
	    		size--;	// reduce size of rest string (before numberpart)
	    		tenP = tenP * 10; // next number -> next power of ten
    		}
    		
    		if ( size == name.length )
    		{
    			return name;
    		}
    		let newName= name.substring(0, size);
    		
    		let no = parseInt(numberAtEnd);
    		let n1 = ""+(no+1);
    		let zeros = name.length - n1.length - newName.length;
			for ( let i = 0; i < zeros; i++ )
			{
				newName += "0";
			}
    		newName += (no+1);
    		return newName;
    	}
    	return name;
    },
   
    showAttachments(line)
    {
    	let attList=line.attachments;
		let buttonArr = new Array();
		let that = this;
		for ( let i in attList )
		{
		    let fname = this.entityMap.Files.data.find(p=>p.id==attList[i]).label;
		    if ( fname.toLowerCase().endsWith('pdf') )
		    {
		    	buttonArr.push( { text: 'View ' + fname, onClick: function(){
					            that.download(attList[i], 'view');
					          }});
		    }
		    buttonArr.push( { text: fname, onClick: function(){
					            that.download(attList[i], 'download');
					          }});
		}
        buttonArr.push( { text: 'Cancel', onClick: function(){
					            
					          }});
    	this.$iosAlertView({
	      title: 'Attached files for ',
	      text: line.name,
	      buttons: buttonArr
	    });
    },
    
    createNewLine()
    {
    	let line = {};
    	
    	let today = new Date();
    	for ( let i in this.metadata.columns )
		{
			let col = this.metadata.columns[i];
//			line[col.column] = null;
		}
		
		line.id = 0;
    	line.tenantId = parseInt(sessionStorage.tenantId);
    	line.fromUser = parseInt(sessionStorage.userId);
    	line.version = 1;
    	if ( this.selectedUnit && this.selectedUnit.id)
    	{
    		line.salesUnitId = parseInt(this.selectedUnit.id);
    	}
    	if ( !line.salesUnitId )
    	{
    		let availableUnits = this.unitOptions.filter( p=>p.id > 0);
    		if ( availableUnits && availableUnits.length === 1)
    		{
				line.salesUnitId = availableUnits[0].id;
			}
    	}
    	
    	line.received = today.toISOString().split('T')[0];
    	line.net = 0;
    	line.noOfSpots = 0;
    	line.fromDate = new Date(today.getFullYear(), today.getMonth() + 1, 2).toISOString().split('T')[0];
  	    line.untilDate = new Date(today.getFullYear(), today.getMonth() + 2, 1).toISOString().split('T')[0];
  	    let list = this.limit( this.data);
    	if ( list && list.length > 0 )
    	{
    		//alert(JSON.stringify(list[list.length-1]))
    		if (!line.salesUnitId)
    		{
    			line.salesUnitId = list[list.length-1].salesUnitId;
    		}
    		
    		let ref = this.parseName( list[list.length-1].name);
    		if ( ref )
    		{
    			line.name = ref;
    		}
    		line.currencyId = list[list.length-1].currencyId;
    		line.typeId = list[list.length-1].typeId;
    		line.channelId = list[list.length-1].channelId;
    		line.agencyName = list[list.length-1].agencyName;
    		line.advertiserName = list[list.length-1].advertiserName;
    		line.productName = list[list.length-1].productName;
    		line.noOfSpots = list[list.length-1].noOfSpots;
    		line.net = list[list.length-1].net;
    		line.salesUnitId = list[list.length-1].salesUnitId;
    	}
    	this.newLine = line;
    	this.rowChanged[list.length]=true;
    },
    ctxMenu(event, data, idx) 
      { 
         event.preventDefault();
         this.$refs.menu.open(event, {data: data, row: idx} )
      },
      
    sendToTraffic(line, rowIdx) {
    	line.sentToTraffic = new Date().toISOString().split('T')[0];
    	line.status = 2;
    	line.trafficUser = null;
    	this.update( line, rowIdx);
    },
    setStatus(line, status, rowIdx) {
        if ( line.status !== status )
        {
	    	line.status = status;
	    	this.update( line, rowIdx);
	     }
    },
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 14%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 14%; height: calc(100vh - 120px); padding: 2ex;"
    },
    edit(r, c, fld, line) 
    { 
    	if ( this.editable( line, fld))
    	{
	    	this.editRow = r; 
	    	this.editCell = c; 
	    	this.jumpToNext=false; 
	    	this.editField = fld; 
	    	if (fld) 
	    	{
	    		this.valueBefore = this.data[r][fld.show];
	    	}
	    }
    },
    setDropRow(line, rowIdx) 
    { 
    	if ( this.editable( line))
    	{
	    	this.dropRow = rowIdx;
	    }
    },
    closeEdit( col, line) 
    {  
    	if ( col && line && col.entity === 'Advertiser')
    	{
    		this.findAgency( line, line.advertiserName)
    	}
    	else if ( col && line && col.entity)
    	{
    		this.findEntity( col, line, line[ col.show ])
    	}
    	this.rowChanged[this.editRow] = true; if (this.jumpToNext) { this.jumpToNextColumn() } else {this.edit(-1,-1);} 
    },
    findAgency( line, clientName)
	{
		if ( !clientName ||  clientName.length < 3)
		{
			return;
		}
	    let that = this;
	    
		HTTP.post( opsAPI+"/findEntity/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/Advertiser/"+clientName, line)	
   		 .then( response => 
            {
           	if ( response.data.foundClients && response.data.foundClients.length )
			{
				that.$nextTick(function () {
					that.$set( line, 'advertiserName', null);
					that.clients = response.data.foundClients.map(p => { return { id: p.name, label: p.name, visible: true}});
					
				});
			}
			else
			{
	           	if ( response.data.first)
	           	{
	               	that.$set( line, 'advertiserName', response.data.first);
	           	}
	           	if (response.data.second && (!line.agencyName || !line.agencyName.length < 2))
	           	{
	               	that.$set( line, 'agencyName', response.data.second);
	           	}
				if (response.data.list )
	           	{
	               	that.products[line.advertiserName] = response.data.list.map(p => { return { id: p.name, label: p.name, visible: true}});
					that.$set( line, 'productName', null);
					//alert( line.advertiserName+"->"+ JSON.stringify( that.products[line.advertiserName]));
	           	}
			}
            }).catch(e => {
          		    
                   showError( that.$toast, "findAgency", e);
               });   
	},
	findEntity( col, line, nameSearch)
	{
	    let that = this;
		HTTP.post( opsAPI+"/findEntity/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+col.entity+"/"+nameSearch, line)	
		 .then( response => 
         {
        	//alert(response.data.first)
        	if ( response.data.first)
        	{
            	that.$set( line, col.show, response.data.first);
        	}
        	
         }).catch(e => {
       		    
                showError( that.$toast, "findEntity", e);
            });   
	},
    jumpToNextColumn()
    {
    	this.editCell++;
    	this.jumpToNext=false; 
    	this.$nextTick(function () {
        	this.$nextTick(function () {
        		this.$refs.ifldEdit[0].open();
         	});
         });
    	
    },
    cancelEdit() { this.data[this.editRow][this.editField.show] = this.valueBefore;  this.rowChanged[this.editRow] = false; this.edit(-1,-1); },
    findColumn(column) { 
       return this.metadata.columns.find(p => p.column === column); 
    },
    clearable() { if ( sessionStorage.salesUnitId) { return sessionStorage.salesUnitId==0; } return false;},
    getColumnStyle(col, idx)
    {
    	let wTotal = this.widthCalculated;
    	let inc = 0;
    	if ( this.editCell >= 0 ) 
    	{
    		wTotal += inc;
    	}
    	if ( this.editCell === idx )
    	{
    		return "width: " + ((parseInt(col.w)+inc)/wTotal*100) +"%; background-color: "+getBG()+"; color: "+getFG();
    	}
        return "width: " + (parseInt(col.w)/wTotal*100) +"%; background-color: "+getBG()+";color: "+getFG();
    },
    getColumnDetailStyle(col, line)
    {
    	if ( !this.editable( line) )
    	{
    		return "cursor: not-allowed !important;font-size:"+this.fontSize+"px;";
    	}
     	return "font-size:"+this.fontSize+"px;";
    },
    limit( data)
    {
        //let tmp = [...data];
        let tmp = this.doFilter( data);
    	let array = this.doOrderBy( tmp);
    	if ( this.newLine )
    	{
    		array.push( this.newLine);
    	}
    	
    	return array;
    },
    
	checkTraffic()
	{
	    let that = this;
		HTTP.post( opsAPI+"/countSalesRequests/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/1/toTraffic")	
				    		 .then( response => 
				             {
				                that.$set( that, 'notifyTraffic', response.data);
				             }).catch(e => {
				           		    
				                    showError( that.$toast, "loading sales-requests", e);
				                });   
	},
	checkDelivery()
	{
	    let that = this;
		HTTP.post( opsAPI+"/countSalesRequests/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/2/ReadyForDelivery")	
				    		 .then( response => 
				             {
				                that.$set( that, 'notifyDelivery', response.data);
				             }).catch(e => {
				           		    
				                    showError( that.$toast, "loading sales-requests", e);
				                });   
	},
    print( line, col)
     {
        //return spot[ col.show ];
     	let val = line[ col.show ];

     	if ( ! val )
     	{
     		return "-";
     	}
     	if ( col.type === 'date' )
     	{
     		let arr = val.split('-');
     		return arr[2]+"."+arr[1]+"."+(parseInt(arr[0])-2000);
     	}
     	if ( col.show === 'name' )
     	{
     		return val;
     	}
     	if ( col.show === 'currencyId' )
     	{	try
     		{
     			return this.entityMap.Currency.data.find(p=>p.id==val).additionalColumn[0];
     		}
     		catch (e)
     		{
     			//alert( val);
     		}
     		
     	}
     	if ( col.show === 'salesUnitId' )
     	{
     		if ( val && ! line.currencyId )
     		{
     			try {
     			line.currencyId = this.fullUnits.find(p=>p.id==val).currencyId;
     			} catch (e) {
     			//
     			}
     		}
     		return this.entityMap.SalesUnit.data.find(p=>p.id==val).label;
     	}
     	if ( col.show === 'channelId' )
     	{
     		return this.entityMap.Media.data.find(p=>p.id==val).additionalColumn[0];
     	}
     	if ( col.show === 'typeId' )
     	{
     		return this.entityMap.PlacementType.data.find(p=>p.id==val).label;
     	}
     	if ( col.show === 'fromUser' )
     	{
     		return this.entityMap.Fromuser.data.find(p=>p.id==val).label;
     	}
     	if ( col.show === 'status' )
     	{
     		return this.entityMap.status.data.find(p=>p.id==val).label;
     	}
		if ( col.show === 'productName' )
     	{
     		return val;
     	}
     	if ( col.show === 'attachments' )
     	{
     		return val?val.length:0;
     	}
     	if ( col.boolean )
     	{
     		if ( val )
     		{
     			return "X";
     		}
     		return "";
     	}
     	if ( isNaN( val) )
     	{
     		return val?val:"";
     	}
     	try
     	{
     		
     		return val ? formatNumber( val ) : val;
     	}
     	catch(e) {
     		//
     	}
     },
    getOrderBy( col )
     {
     	for ( var i in this.orderBy)
     	{
     		let iCol = this.orderBy[i];
     		if ( iCol.show === col.show )
     		{
     		    if ( iCol.sorting > 0 )
     		    {
     				return "A"+(parseInt(i)+1);
     			}
     			return "D"+(parseInt(i)+1);
     		}
     	}
     	return "";
     },
    setOrderBy( col )
     {
        var add = true;
     	for ( var i in this.orderBy)
     	{
     		let iCol = this.orderBy[i];
     		if ( iCol.show === col.show )
     		{
     		    if ( col.sorting === 1 )
     		    {
     		    	col.sorting = -1;
     		    }
     		    else
     		    {
     				this.orderBy.splice( i, 1);
     				col.sorting = 0;
     			}
     			add = false;
     			break;
     		}
     	}
     	if ( add )
     	{
     		this.orderBy.push( col );
     		col.sorting = 1;
     	}
     	//this.doOrderBy();
     	this.$forceUpdate();
     	
     },
    doFilter(data)
     {
      	let tmp = data;
      	
     	tmp =  tmp.filter(line => (!line.status) || this.statusArray.find(p=>p.id===line.status) );
     	
     	//this.checkMark();
     	return tmp;
     },
     doOrderBy(data)
     {
        let that = this;
        
     	data.sort(function(a,b) {
	     	for ( var i in that.orderBy)
	     	{
	     		let iCol = that.orderBy[i];
	     		let v1 = a[ iCol.show ];
	     		let v2 = b[ iCol.show ];
	     		
	     		if ( ! v2 || v1 > v2 )
	     		{
	     			return iCol.sorting;
	     		}
	     		if ( ! v1 || v1 < v2 )
	     		{
	     			return -iCol.sorting;
	     		}
	     	}
	     	return 0;
	     });
		return data;
     },
    dropFile(event, line, rowIdx, lineREF)
    {
      event.preventDefault();
      event.stopPropagation();
      if ( ! line.id )
      {
      	showError( this.$toast, "Line " + line.name+ " is not saved yet", "");
      	return
      }
      this.showDropzone = false;
      const files = event.dataTransfer.files;
      const itemArray = [...files];
      
      const item = itemArray.find((i) => i.type.startsWith('application/pdf') || i.type.startsWith('application/vnd.openxmlformats'));
      let that = this;
      this.$refs.confirm.confirm( {text: 'Attach ' + item.name + ' to ' + line.name, title: "Add attachment", button1: "Cancel", button2: "Add file"}).then(x => {
       	  const formData = new FormData();
       	  this.startLoader();
       	  that.leaveLine(lineREF);
	      formData.append('file', item);      
	      if (item) this.upload(formData, line, rowIdx, item.name);
       }).catch((err) => { 
       that.leaveLine(lineREF); 
       });
    },
    async upload(data, line, rowIdx, name) {
      let uploadFile = await this.uploadFile(data, line, rowIdx, name);
    },
    uploadFile(formData, line, rowIdx, name) 
    {
      //this.cancelTokenSource = axios.CancelToken.source();
      let that=this;
      HTTPMP.put( opsAPI+"/fileUpload4Tracker/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+line.id, formData)
	            .then( response => 
	            { 
	                line.attachments = response.data.attachments;
	                for ( let i in line.attachments )
					{
					    let chk = that.entityMap.Files.data.find(p=>p.id==line.attachments[i]);
					    if ( ! chk )
					    {
					    	that.entityMap.Files.data.push({id: line.attachments[i], label: name});
					    }
					}
	                this.entityMap.Files.data.push();
	                that.stopLoader();
	                that.$toast.success("File '"+name+"' uploaded to '"+line.name+"'", 'Ok', { timeout: 1500, position: "topRight" });
	                that.$forceUpdate();
      }).catch((err) => (showError( that.$toast, "File '"+name+"' not uploaded", err)))

    },
    doReload() {
    	let that = this;
    	this.startLoader();
    	
    	
    	if ( this.selectedUnit && this.selectedUnit.id >= 0 )
    	{
	    	let dateFrom = new Date(that.fromDate).toISOString().split('T')[0];
	    	let dateUntil = new Date(that.untilDate).toISOString().split('T')[0];
	    	
	    	HTTP.post( opsAPI+"/getSalesRequests/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+that.selectedUnit.id+"/"+sessionStorage.userId+"/"+dateFrom+"/"+dateUntil+"/0/0", {})
    		 .then( response => 
             {
             	that.data = response.data.data; // that.doOrderBy( that.doFilter(response.data.data));
				that.newLine = null;
				if ( that.data && !that.data.length)
				{
					that.createNewLine();
				}
                that.metadata = response.data.metadata;
                that.allowed = response.data.allowed;
                that.entityMap = response.data.entity2DataMap;
				
				that.rowChanged = [];
				that.closeEdit();
				that.stopLoader();
				
				if ( that.statusArray.length === 3 && !that.statusArray[0].id )
				{
					that.statusArray = that.entityMap.status.data.filter( p=> that.statusArray.includes( p.id));
				}
                    
             }).catch(e => {
           		    that.stopLoader();  
                    showError( that.$toast, "loading sales-requests", e);
                });   
	    }
    },
    deleteLine( line ) {
        let that = this;
		this.$refs.confirm.confirm( {text: "Delete line "+line.name, title: "Delete", button1: "Cancel", button2: "Delete"}).then(x => {
		      if ( line.id > 0 )
		      {
		       	  HTTP.delete( opsAPI+"/deleteRequest/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+line.id)
			        .then( response => 
			        { 
			            that.data =  that.data.filter( l => l.id !== line.id  );
			            that.$forceUpdate();
			        }).catch(e => (showError( that.$toast, "Line '"+line.name+"' not deleted", e)))
			   }
			   else
			   {
			   		that.newLine = null;
		            that.$forceUpdate();
			   }
	    });
    },
    init() {
        let that = this;
		this.startLoader();
		this.checkTraffic();
    	this.checkDelivery();
		let stationLoad = new Promise(function (resolve, reject) {
		HTTP.post( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/allEntities/true/0")
		 .then( response => 
	     {
			let stations = response.data.data;
			stations = stations.filter(p=>p.active && p.directBookable);
			that.stations = stations.map( p => {return {id: p.id, label: p.name, visible: true};});
	   		resolve( that.stations);
	     }).catch(e => {
	            //console.log( JSON.stringify(e));
	            that.$toast.error("loading data for stations: " + e.response.data, 'Error', { position: "topRight" });
				that.stopLoader();
	        });
		});
		
		stationLoad.then( s => {
			if ( !that.unitOptions || ! that.unitOptions.length || !that.selectedUnit || !that.selectedUnit.id)
		    {
	        	that.unitOptions=new Array();
	        	that.fullUnits = new Array();
	        	getSalesUnit( sessionStorage.salesUnitId, sessionStorage.unitId, that.unitOptions, that.selectedUnit, null, that.fullUnits).then( opts => {
	        		that.unitOptions = opts;
	        		that.doReload();
	        	})
	        	
		    }
		    that.setOrderBy( {show: 'name'});
		   	that.stopLoader();
		});	
		
    	
  
    },
    download( fileId, doWhat )
    {
    	let that = this;
    	if ( doWhat == 'download')
        {
	    	HTTP.post( opsAPI+"/download/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+fileId)
	        .then( response => 
	        { 
                let downloadResponse = response.data;
           		that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
        	}).catch(e => (showError( that.$toast, "file download error", e)))
        }
        else
        {
        	
        	HTTP.post( opsAPI+"/download/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+fileId)
	        .then( response => 
	        { 
               
                that.$refs.pdfView.open(response.data.file)
           		
        	}).catch(e => (showError( that.$toast, "file download error", e)))
        }

    },
    forceFileDownload(base64, name) 
	{  
      const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
      const link = document.createElement('a');
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    },
    base64ToArrayBuffer(base64) {
	    var binary_string = atob(base64);
	    var len = binary_string.length;
	    var bytes = new Uint8Array(len);
	    for (var i = 0; i < len; i++) {
	        bytes[i] = binary_string.charCodeAt(i);
	    }
	    return bytes.buffer;
	},
	completeLine( line )
	{
		let that = this;
		let promDefer = defer();
		HTTP.post( opsAPI+"/completeLine/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+line.id, line)
        .then( response => 
        {
             Object.assign( line, response.data);
			 promDefer.resolve( line);
        }).catch(e => {
            showError( that.$toast, "update salesRequest", e);
			promDefer.reject();
        });
		return promDefer;
	},
	async checkMandatory( line )
	{
		let that = this;
		let promDefer = defer();
		await this.completeLine(line).then( line =>
		{
			
			for ( let i in this.metadata.columns )
			{
				let col = this.metadata.columns[i];
				let cx = this.columns.find( p=>p.show==col.column);
				if ( cx && col.mandatory )
				{
					if ( ! line[col.column] )
					{
						showError( this.$toast, "Check", cx.name+" is mandatory:: "+(line[col.column]));
						promDefer.reject();
					}
				}
			} 
			promDefer.resolve( line);
		}).catch( e=> {
			
			promDefer.reject();
		});
		return promDefer;
	},
	saveAll()
	{
		let that = this;
		let rows = that.limit( that.data);
		for ( let idx in that.rowChanged )
		{
			let changed = that.rowChanged[idx];
			if ( changed )
			{
				let line = rows[idx];
				that.update( line, idx);
			}
		}
	},
    async update(line, rowIdx) 
    { 
            let that = this;
            let statusSave = line.status;
        	 if ( ! line.status )
        	 {
        	 	line.status = 1;
        	 }
			
        	 await this.checkMandatory( line ).then( line => {
			
	        	if ( line && line.id > 0 )
	        	{
	        	 HTTP.put( opsAPI+"/updateData/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/SalesRequest/"+line.id, line)
	                    .then( response => 
	                    {
	                         Object.assign( line, response.data.data);
	                         //that.rowChanged[ rowIdx] = false;
	                         //that.data[ rowIdx] = line;
	                         that.jumpToNext = false;
	                         that.closeEdit();
	                         
	                         that.$set( that.rowChanged, rowIdx, false);
	                         that.$forceUpdate();
	                        
	                    }).catch(e => {
	                        line.status = statusSave;
	                        showError( that.$toast, "update salesRequest", e);
	                    });
	           } else
	           {
	            // alert("ins");
	           	HTTP.put( opsAPI+"/updateData/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/SalesRequest/0", line)
	                    .then( response => 
	                    {
	                         line = response.data.data;
	                         that.data.push ( line );
	                         that.newLine = null;
	                         that.jumpToNext = false;
	                         that.$set( that.rowChanged, that.data.length-1, false);
	                         that.closeEdit();
	                         
	                    }).catch(e => {
	                        line.status = statusSave;
	                   	    showError( that.$toast, "insert salesRequest", e);
	                    });
	           }
            });
        },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
    stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	},
	closeEditorDetail()
	    {
	        this.showEditorDetail = false;
	    },
	nodeToOpen( event, module, dataId)
	    {
	        
	    	
	    },
	calcWidths()
	{
		let wTot = 0;
		var columns = "";
		for ( var i in this.columns )
     	{
     		wTot += this.columns[ i].w;
     	}
     	this.widthCalculated = wTot;
	},
	storeSettings() 
	{
		try
		{
			let obj = restore();
			if ( !obj )
			{
				obj = {};
			}
			obj.from = this.fromDate;
			obj.to = this.untilDate;
			obj.unit = this.selectedUnit;
	    	store( obj);
		} catch(e) { showError( this.$toast, "storeSettings()", e);  }
	},
  },
  beforeDestroy() {
	  this.storeSettings();
  },
  created() {
    const today = new Date();
    if ( this.$route.params && this.$route.params.from )
    {
    	this.fromDate = new Date( this.$route.params.from);
    	this.untilDate = new Date( this.$route.params.until);
    }
    else
    {
    	let restoreView = restore();
      	if ( restoreView && restoreView.from )
      	{
      		this.fromDate = restoreView.from;
      		this.untilDate = restoreView.to;
      		this.selectedUnit = restoreView.unit;
      		
      	}
      	else
      	{
      		this.selectedUnit = {};
      		this.fromDate = new Date(today.getFullYear(), today.getMonth() + 1, 2).toISOString().split('T')[0];
		  	this.untilDate = new Date(today.getFullYear(), today.getMonth() + 4, 1).toISOString().split('T')[0];
      	}
    }
  	this.calcWidths();
  	this.init();
  	initAppMode();
  	this.$nextTick(function () {
	  	// force recalc () (open&close)--> workaround as the first time the context menu opens at (0,0)
	   	try {
	   	this.$refs.menu.open( {}); } catch (e) {
	   	//
	   	}
	   	this.$refs.menu.close();
   	});
  },
  watch: {
	//selectedUnit: function() { this.doReload(); },
	fromDate: function() { this.doReload(); }
	
  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
		    // Code that will run only after the
		    // entire view has been re-rendered
		    //console.log("stopping loader");
		    if (this.loadingActive)
		    {
		        timers.push( setTimeout( this.stopLoader, 100));
		    }
		  })
 	}
}
</script>
<style lang="scss" scoped>

.transition {
 	transition: background-color .5s ease-out !important;
 	-webkit-transition: background-color .5s ease-out !important;
   //-webkit-transition: all 5s;
 }

.myButton {
	//width: 18%;
    //padding: 6px 8px;
    outline: none;
    //border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    //background-color: #eef;
    border: none;
    color: rgb(0, 0, 0);
    cursor: pointer;
    //margin-right: 4pt;
}  


.myButton:disabled {
    cursor: not-allowed;
    color: #aaa;
}  
.myButton:hover {
    //text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    //border: 1pt outset green;
    color: #fff;
    
    background-color: #666;
}  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  font-weight: bold; 
  width: 100% !important; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  //background-color: #eef; 
 }

.floatLeft {
    display: inline-flex;
    float: left;
}
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 8pt;
}
.SVFont {
	font-size: 9pt;
}
.SVcontrolLabelAvail {
	padding-top: 9pt;
	font-weight: bold;
	font-size: 9pt;
	cursor: pointer;
	color: #aaa;
}
.btn {
	height: 2.2em !important;
	padding-left: 0.5em;
	padding-right: 0.5em;
}
.input80 {
	width: 90% !important;
	display: inline-block;
	padding: 0;
	margin:0;
}
.input20 {
	width: 10% !important;
	display: inline-block;
	text-align: right;
	vertical-align: top;
	padding: 0;
	margin:0;
}
.tableHeader {
  color: #000;
  background-color: #444;
}
.reqDetail { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8pt;  
  width: 100% !important; 
  height: 24pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  overflow: hidden;
  //background-color: #aaa; 
  //background-color: #e8f0e8 ; 
}
.reqLine {
  //color: #000;
  //background-color: #fff ; 
}
.reqDetail:hover {
  //background-color: #e8f0e8 !important; 
}
.reqLineBG {
  background-color: #e8f0e8 !important; 
}
.reqLineBGDM {
  background-color: #888 !important; 
  color: #eef;
}
.reqLine:hover {
  //background-color: #cff !important;
  color: #004;
  //font-weight: bold; 
  text-shadow: 4px 4px 4px rgba(0.1, 0.1, 0, 0.2);
  cursor: pointer;
}
.dontWrap {
  overflow-x: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.wrap {
  white-space: wrap; /* Don't forget this one */
}
.xbutton {
  width: 85% !important;
  font-weight: bold;
}
</style>
